$co_updates: true;
$roboto-font-family: 'Roboto Regular';

// 2018 Creative refresh
$gluxre_update: true;

//Password Tooltip For password Strengthening
$password_strengthen: true;

// Loyalty Program Price Enabling variable
$loyalty_program: true;