// Abstract specific font names away

$Futurabookc: 'futurabookc';

@font-face {
  font-family: '#{$Futurabookc}';
  src: url('#{$netstorage-font-path}Futura/futurabookc.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$OptimaEL: 'optimael';
$OptimaEL-font: 'OptimaEL';

@font-face {
  font-family: '#{$arsenal}';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

// font-family OptimaDisplayLight is deprecated for Russia.
// replaced with OptimaEL regarding EMEABEX-505.
// replaced with Arsenal
@font-face {
  font-family: 'OptimaDisplayLight';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

// font-family Optima Display Roman/ Optima Display Roman is deprecated for Russia.
// replaced with OptimaEL regarding EMEABEX-505.
// BEXEL-1322: Replaced with Arsenal
@font-face {
  font-family: 'Optima Display Roman';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

// font-family OptimaDisplayBook is deprecated for Russia.
// replaced with OptimaEL regarding EMEABEX-505.
// BEXEL-1322: Replaced with Arsenal
@font-face {
  font-family: 'OptimaDisplayBook';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$BodoniCyliric-font: 'BauerBodoniStd';

@font-face {
  font-family: '#{$BodoniCyliric-font}';
  src: url('#{$netstorage-font-path}cyrillic/Bodoni/Bodoni-Cyrillic2.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$NarzissCyliric-font: 'Narziss-pro-Cyliric';

@font-face {
  font-family: '#{$NarzissCyliric-font}';
  src: url('#{$netstorage-font-path}Narziss/NarzissProCy-Regular-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$Helvetica-55-roman-font: 'Neue Helvetica Pro Cyrillic 55 Roman';

@font-face {
  font-family: '#{$Helvetica-55-roman-font}';
  src: url('#{$netstorage-font-path}helvetica/3282B4_0_0.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$TheanoDidot-Regular-font: 'TheanoDidot-Regular';

@font-face {
  font-family: '#{$TheanoDidot-Regular-font}';
  src: url('#{$netstorage-font-path}Didot/TheanoDidot-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: italic;
}

$price-font: 'Helvetica Neue';

// Screen sizes
$xsmall-up: 320px;
$xsmall-device-up: 400px;
$small-device-up: 360px;
