// Abstract specific font names away
$optimalight: 'Arsenal';

@font-face {
  font-family: 'OptimaEL';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
}

$optimaregular: 'Arsenal';

