$ru_container_class: '.sign-in-component_ru';

#{$ru_container_class} {
  // Common block
  &__header,
  &__container {
    width: auto;
    max-width: 760px;
    margin: auto;
  }
  &__container {
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
    }
    h3 {
      @include swap_direction(padding, 0 0 10px 0);
      color: #{$color-almost-black};
      text-transform: uppercase;
      font-size: 11px;
      font-family: #{$akzidenz} !important;
    }
  }
  // Sign in header block
  &__header {
    @include swap_direction(margin, 60px auto);
    @include swap_direction(padding, 20px 0px);
    font-size: 40px;
    text-align: center;
    border-bottom: 2px solid #{$color-border--estee_edit};
  }
  // Errors for PG-side
  &__errors-container {
    width: 100%;
    .errors-container {
      &__errors {
        @include swap_direction(margin, 0px auto 10px);
        width: 70%;
        float: right;
      }
    }
  }
  // Sign in / Register forms
  &__forms-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    .forms-container {
      &__form {
        width: 50%;
        display: table-cell;
        position: relative;
        padding-bottom: 40px;
        input[type='password'],
        input[type='text'],
        input[type='submit'],
        button {
          width: 100%;
        }
      }
      &__form:first-of-type {
        border-#{$rdirection}: 1px solid #{$color-border--estee_edit};
        padding-#{$rdirection}: 21px;
      }
      &__form:last-of-type {
        border-#{$ldirection}: 1px solid #{$color-border--estee_edit};
        padding-#{$ldirection}: 21px;
      }
      &__form:first-of-type .form-item:last-of-type,
      &__form:last-of-type .form-item:last-of-type {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      &__form:first-of-type .form-item:last-of-type {
        padding-#{$rdirection}: 21px;
      }
      &__form:last-of-type .form-item:last-of-type {
        padding-#{$ldirection}: 21px;
      }
      &__forgot-password-link {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
        &-container {
          @include swap_direction(padding, 5px 0px);
        }
      }
    }
  }
  // Block with legal checkboxes
  &__legal-container {
    width: 400px;
    margin: auto;
    text-transform: none;
    .legal-container {
      &__acceptance-block {
        padding-bottom: 10px;
        &_no-checkbox label {
          padding-left: 24px;
          display: inline-block;
        }
        &_error {
          color: #{$color-red};
          a {
            color: #{$color-red};
            :hover {
              color: #{$color-red};
            }
          }
          input[type='checkbox'] ~ .label::before,
          input[type='checkbox'] ~ label::before,
          input[type='radio'] ~ .label::before,
          input[type='radio'] ~ label::before {
            border: 1px solid #{$color-red};
          }
        }
      }
    }
  }
  // Block with forms switcher (visible only on mobile version)
  &__form-switcher-container {
    display: none;
    margin-top: 20px;
    .form-switcher-container {
      &__switcher {
        display: none;
        &-title {
          @include swap_direction(margin, 10px 0px);
          text-align: left;
          text-transform: none;
          font-size: 18px;
        }
      }
    }
  }
  // Overriding styles for mobile version
  @media #{$medium-down} {
    .sign-in-component_ru__header,
    .sign-in-component_ru__errors-container,
    .sign-in-component_ru__container {
      width: 90%;
      max-width: 580px;
      margin: auto;
    }
    &__errors-container {
      .errors-container {
        &__errors {
          width: 100%;
          float: none;
          margin-top: 10px;
        }
      }
    }
    h3 {
      @include swap_direction(margin, 10px 0px);
      text-align: center;
      font-size: 18px;
      font-family: #{$optima};
    }
    &__forms-container {
      .forms-container {
        &__form {
          display: block;
          &:last-of-type {
            display: none;
          }
          &:first-of-type,
          &:last-of-type {
            width: 100%;
            border: none;
            padding: 0;
            float: none;
          }
          &:first-of-type .form-item:last-of-type,
          &:last-of-type .form-item:last-of-type {
            position: relative;
            bottom: 0;
            left: 0;
            height: 40px;
            margin: 0;
            padding: 0;
          }
          .form-item-legal {
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
    &__legal-container {
      width: 100%;
    }
    &__form-switcher-container {
      display: block;
      margin-bottom: 20px;
      .form-switcher-container {
        &__switcher {
          &:first-of-type {
            display: block;
          }
          &-button {
            width: 100%;
          }
        }
      }
    }
  }
  // Overriding some defaults
  label.label {
    text-transform: none;
    letter-spacing: normal;
    margin-top: 10px;
    font-size: 14px;
  }
}

.page-wrapper-checkout {
  .checkout {
    .form-item {
      label {
        text-transform: none;
        display: block;
        letter-spacing: 0.05em;
      }
    }
  }
}

.sign-in-panel {
  .sign-in-component_ru__legal-container {
    .legal-container__acceptance-block {
      padding-bottom: 16px;
    }
  }
}
