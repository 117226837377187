.mpp__product-grid {
  .product-brief__button-quickshop {
    @media screen and (max-width: $xsmall-device-up) {
      line-height: 22px;
      letter-spacing: 0.15em;
    }
    letter-spacing: 0.1em;
    padding: 0px;
  }
  .product_brief__price {
    &-loyalty {
      display: inline-block;
    }
  }
}
/* Needed more specificity to override... */
.extended-mpp {
  .mpp__product-grid {
    .quickshop-inline__price-size {
      font-weight: 400;
    }
  }
}

.section-customer-service {
  .contact-form {
    .contact-form__section--question-types {
      .question-1-desc {
        #question-type-1-f {
          display: block;
        }
      }
    }
  }
}

.spp-product {
  .spp-product__description {
    .order_arrival {
      display: none;
    }
    #delivery_info {
      margin: 0;
      display: none;
      a {
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.15em;
      }
    }
    .edd {
      display: block;
      &.hide {
        display: none;
      }
    }
    .spp-product__personal {
      margin: 0;
      padding-top: 10px;
    }
  }
  .product-full__price {
    padding-right: 10px;
    &-size-select {
      margin-top: 11px;
    }
    &-container {
      width: 195px;
      display: table-cell;
    }
    &-description {
      display: table-cell;
      vertical-align: middle;
    }
    &-loyalty {
      display: table;
      margin: 10px 0;
      &.active {
        font-weight: bold;
      }
      &.tier3,
      &.tier2,
      &.tier1 {
        margin: 0;
      }
      .product-full__price-container {
        width: 180px;
      }
      .product-full__price-text {
        margin-right: 5px;
      }
      .product-full__price-description {
        font-size: 12px;
      }
      &-container {
        margin-bottom: 10px;
      }
      &--link a {
        font-weight: normal;
      }
    }
    &-psize {
      padding-left: 10px;
    }
    &-text {
      margin-right: 20px;
      font-size: inherit;
      display: block;
    }
  }
}

.device-mobile {
  .footer-main-menu {
    a {
      height: auto;
    }
  }
}

@media screen and (max-width: $xsmall-up) {
  .page-sticky-footer {
    .page-sticky-footer__inner {
      padding: 0 5px 0 5px;
    }
    .page-sticky-footer__right {
      .menu {
        li {
          padding: 0 6%;
        }
      }
    }
  }
  #colorbox {
    &.welcome15-overlay {
      #cboxContent {
        .email_popover {
          .email_popover__content {
            width: 300px;
          }
        }
        #cboxClose {
          width: 68px;
          left: 240px;
          text-indent: 0;
        }
      }
    }
  }
}

.page-sticky-footer {
  .page-sticky-footer__right {
    .menu {
      a {
        letter-spacing: 0em !important;
      }
    }
  }
  .page-sticky-footer__right,
  .page-sticky-footer__inner {
    .field-mobile-menu {
      .menu > li {
        padding: 0 8px 0 14px;
        text-align: center;
      }
      .menu > li.first {
        padding-left: 0;
      }
      ul.menu {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.customer-service--landing {
  overflow: hidden;
}

#colorbox.welcome15-overlay {
  .email_popover {
    .email_popover__social_icons {
      top: 490px !important;
      .twitter,
      .pinterest,
      .instagram {
        display: none;
      }
      .facebook {
        img {
          float: none;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.main {
  #search-results {
    .pagination {
      a {
        &.prev {
          margin-right: 10px;
        }
      }
    }
  }
  #product-results {
    .product-brief__button-quickshop {
      padding: 0;
    }
  }
}

.video_local_mobile {
  .video-local-mobile-eyebrow,
  .video-local-mobile-headline {
    margin-left: 6.25%;
    margin-bottom: 10px;
  }
}

#video-local-container {
  .video-local-title-wrapper {
    margin-left: 6.25%;
    margin-bottom: 10px;
  }
  #video-local-thumbnail {
    width: 100%;
  }
}

.flexslider {
  .mb-slideshow {
    .mb-slide-share {
      a.twitter,
      a.pinterest {
        display: none;
      }
      li {
        padding: 0 5px 0 40px;
      }
    }
  }
}

.trust_mark_footer {
  .trust_mark_footer__link {
    width: 72px;
    height: 120px;
    background-image: url(/media/images/global/trustmark_white_ru.png);
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: block;
    margin: auto;
    background-position-y: 22px;
  }
}

@media only screen and (max-width: 640px) {
  #tmb_container {
    height: 100% !important;
  }
}

.section-estee-stories-article-note-estee-lauder {
  .formatter-estee-edit__content {
    .img-center {
      img {
        width: 100%;
      }
    }
  }
}

.section-estee-stories-article-princess-grace-monaco-1971 {
  .formatter-estee-edit__content {
    .ee-article-image__image {
      margin: 0;
    }
  }
}

.section-estee-stories-article-way-primp {
  .basic-textarea-v1 {
    h4 {
      font-size: 40px;
    }
  }
}

.ff-quiz__slide--content {
  .ff-quiz__headline--tertiary {
    letter-spacing: 0;
  }
  .ff-quiz__coverage-image {
    font-size: 55px;
  }
}

.page-footer {
  .footer-find-us {
    border-bottom: 1px solid $color-medium-blue;
    .footer-social-links {
      .vk {
        background-image: url(/media/export/cms/homepage/VK-icon.png);
        background-repeat: no-repeat;
        width: 35px;
        height: 24px;
        margin: 0 15px;
      }
      .vk:hover {
        background-image: url(/media/export/cms/homepage/VK-icon-hover.png);
      }
    }
  }
}

.section-store-locator.device-mobile {
  .store-locator {
    .store-locator__sub-pages {
      .local-search,
      .intl-search {
        min-height: 300px;
      }
    }
  }
}

.boutique.brow-contouring {
  #tips_section-1 {
    .header-image {
      top: 35%;
    }
  }
}

#tmb_container {
  .mb_boutique_content {
    h2 {
      font-size: 8vw !important;
    }
  }
  .mb_boutique_quote {
    h3 {
      font-size: 10vw !important;
    }
  }
}

#tips_boutique {
  h1 {
    font-size: 10.75vw !important;
  }
}

.ff-results {
  .ff-results__matches {
    display: none;
  }
}

.spp {
  .product-full {
    .free_standard_shipping_returns {
      font-weight: normal;
    }
  }
}

.page-wrapper {
  .review-submit {
    width: 100%;
  }
}

#colorbox.edd_popover {
  #cboxContent {
    .order_arrival_details {
      margin-top: 35px;
    }
  }
}

.sop {
  .product-full__attribute {
    h5 {
      font-family: 'AkzidenzGrotesk W1G' !important;
    }
  }
}

.spp {
  .product-full__offer,
  .spp-product__attribute,
  .spp-product__details {
    h5 {
      font-family: 'AkzidenzGrotesk W1G' !important;
    }
  }
}

.field-mobile-template {
  .section-wrapper.mpp {
    .holiday-image img {
      height: 150px;
    }
    .mpp__product-sort-container {
      position: relative;
    }
  }
  .holiday-2016_side-nav-wrapper.open {
    .holiday-2016_side-nav {
      top: 188% !important;
      li:first-child {
        border-top: 1px solid rgba(251, 245, 214, 0.3);
      }
    }
  }
}

.elb-privacy-policy {
  h1.pp__title {
    font-size: 39px;
  }
}

@media only screen and (max-device-width: $small-device-up) {
  .elb-privacy-policy {
    h1.pp__title {
      font-size: 37px;
      letter-spacing: -4px;
    }
  }
}

.page-utilities__cart-button {
  padding-right: 0;
}

.email_popover {
  .instagram {
    display: block !important;
  }
  .facebook {
    img {
      float: left !important;
      margin-right: 13px !important;
    }
  }
  .youtube {
    display: block !important;
    img {
      margin-left: -22px !important;
    }
  }
}

#colorbox.welcome15-overlay {
  #cboxContent {
    #cboxClose {
      width: 75px;
      left: 301px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 1024px) {
  #lhc_status_container {
    height: auto !important;
  }
  .page-utilities__signin-text {
    a {
      display: none;
    }
  }
  .page-utilities__account-text {
    display: none;
    .user-greeting {
      line-height: 2;
    }
  }
}

@media only screen and (min-width: 320px) {
  .page-utilities__signin-text {
    font-size: 6px;
    position: relative;
    right: -25px;
  }
  .page-utilities__search-button {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 360px) {
  .page-utilities__signin-text {
    font-size: 8px;
    padding-top: 2px;
    position: relative;
    right: -8px;
  }
}

@media only screen and (min-width: 375px) {
  .page-utilities__signin-text {
    font-size: 8px;
    right: -19px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .pcem_layout_1_mob {
    .pc_em__1_swatch_dot_7.pc_em_swatch_selected,
    .pc_em__1_swatch_dot_8.pc_em_swatch_selected,
    .pc_em__1_swatch_dot_9.pc_em_swatch_selected,
    .pc_em__1_swatch_dot_10.pc_em_swatch_selected {
      opacity: 0 !important;
    }
    #pc_em_swatch_label_7,
    #pc_em_swatch_label_8,
    #pc_em_swatch_label_9,
    #pc_em_swatch_label_10 {
      opacity: 0 !important;
      color: #000 !important;
    }
  }
  .pcem_layout_3_mob {
    .pc_em__3_swatch_dot_15.pc_em_swatch_selected {
      opacity: 0 !important;
    }
    #pc_em_swatch_label_15 {
      opacity: 0 !important;
      color: #000 !important;
    }
  }
}

.header-items {
  button.page-utilities__search-button {
    padding: 0px 0px 0px 0px;
  }
  .page-utilities__account {
    button.page-utilities__account-button {
      float: right;
      .page-utilities__signin-text {
        font-size: 10px;
      }
    }
  }
}

.section-skincare {
  #colorbox.welcome15-overlay {
    .email_popover {
      margin-left: 41px !important;
      margin-right: auto !important;
      margin-top: 0px !important;
      .email_popover__social_icons {
        top: auto !important;
      }
    }
    #cboxContent {
      #cboxClose {
        left: 250px;
      }
    }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
  .header-items {
    .page-branding {
      margin-left: 10px;
      .page-branding__logo {
        left: 40%;
      }
    }
    button.page-utilities__search-button {
      padding: 0px 8px 0px 8px;
    }
    button.page-navigation__menu-toggle {
      width: 18px;
    }
    .page-utilities__account.page-utilities__item {
      margin-right: -9px;
      button.page-utilities__account-button {
        float: right;
        .page-utilities__signin-text {
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (device-width: 320px) and (device-height: 568px) {
  .section-skincare {
    #colorbox.welcome15-overlay {
      .email_popover {
        margin-left: 17px !important;
      }
      #cboxContent {
        #cboxClose {
          left: 240px !important;
        }
      }
    }
  }
}

.page-wrapper {
  height: 100%;
}

@media (min-width: 320px) and (max-width: 768px) {
  #ship-calc-overlay {
    .close-popup {
      margin-left: 0 !important;
    }
  }
}

.shipping-calc-results {
  display: block;
  .shipping-cost {
    .country-container {
      float: left;
      margin: -10px 0px 10px 0px;
    }
  }
}

@media screen and (min-width: 767px) {
  .shipping-calc-results {
    .shipping-cost {
      .country-container {
        float: none !important;
        display: block;
      }
    }
  }
}

@media (max-width: 320px) {
  #colorbox.welcome15-overlay {
    #cboxContent {
      #cboxClose {
        width: 66px !important;
        left: 242px !important;
      }
    }
  }
}

.night-experience-page {
  .el_boutique_wrapper {
    .np_mod1_video {
      display: none !important;
    }
  }
}

.el_boutique_wrapper {
  .np_mod1_copy {
    .shop_now_dark {
      position: absolute;
    }
  }
}

.brand-renutriv {
  .page-wrapper {
    overflow: visible;
  }
}

#password_reset {
  .page-wrapper {
    height: auto;
  }
}

.device-mobile {
  .shipping-cost-widget {
    p.delivery_region {
      padding-top: 20px;
    }
    .shipping-cost {
      margin: 1.5em 0;
    }
  }
  a.shipping_calc {
    text-decoration: underline;
  }
  #ship-calc-overlay {
    h3 {
      font-family: 'Arsenal' !important;
      font-size: 28px;
    }
    .country-container {
      float: left;
      width: 100%;
      margin-bottom: 6px;
      font-family: 'AkzidenzGrotesk W1G' !important;
      input,
      select {
        width: 100%;
        float: left;
      }
      select {
        font-family: 'AkzidenzGrotesk W1G' !important;
        font-size: 12px;
        background-color: #fff !important;
        color: #040a2b !important;
        border-color: #b3b3b3;
        background-image: url('/media/images/background-images/background-image13.png');
      }
    }
    .calculation-result {
      .shipping-type {
        font-weight: bold;
        margin-bottom: -15px;
      }
    }
    .close-popup,
    .input-btn {
      padding: 8px 10px;
      background: #040a2b;
      border: none !important;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      cursor: pointer;
      font-weight: bold;
      text-decoration: none;
      color: #fff;
      border-radius: 0px !important;
    }
    .close-popup {
      float: right;
      margin-right: 2px;
    }
    .submit_popup {
      margin-top: 15px;
    }
    .missing_region,
    .missing_city {
      color: red !important;
    }
  }
  .shipping-cost-widget {
    .shipping-price {
      margin-right: 5px;
    }
    .shipping-title {
      font-weight: bold;
    }
    .city_region {
      text-transform: uppercase;
    }
  }
  .shipping-calc-link {
    display: block;
    font-size: 19px;
  }
}

.spp {
  .spp-product__additional-section {
    .spp-howtouse {
      .spp-howtouse__header {
        text-transform: lowercase;
      }
      .spp-howtouse__header.headline--secondary::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

body.device-mobile {
  .multi_use_mpp_v1 {
    h1.headline_content__headline {
      font-family: OptimaEL !important;
    }
  }
}

.page-header .header-items {
  position: relative;
  @media #{$small-only} {
    position: relative;
  }
  @media #{$medium-up} {
    padding: 0;
  }
}

.waitlist {
  & &__terms_link {
    padding: 0;
    width: 95%;
    font-family: $akzidenz !important;
    #ACCEPTED_PRIVACY_POLICY {
      & ~ label::after {
        border: 1px solid $color-white;
        background-image: url('/media/images/background-images/background-image12.png');
      }
    }
    label {
      letter-spacing: 0.05em;
      padding-left: 22px;
    }
  }
}

.parallax {
  &__container {
    .chat-parallax {
      &__content {
        .chat-parallax {
          &__headline {
            &.headline-font {
              font-size: 35px;
              padding: 0 10px;
            }
          }
        }
      }
    }
  }
}

.sidebar-page {
  &__content {
    .account-favorites {
      &__list {
        li {
          float: none;
        }
      }
    }
  }
}
