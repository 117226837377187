$social_login_container_class: '.social_login_v2';
$color-vkontakte-theme: #4a76a8;
$color-facebook-theme: #3c5a99;

#{$social_login_container_class} {
  // Block with `OR` word placed before social-login
  &__delimeter {
    text-align: center;
    height: 10px;
  }
  &__delimeter-horizontal {
    background-color: #{$color-border--estee_edit};
    height: 2px;
    margin-top: 20px;
  }
  &__delimeter-label {
    @include swap_direction(padding, 0px 6px);
    background-color: #{$color-white};
    top: -8px;
    position: relative;
    text-transform: uppercase;
    display: inline;
  }
  // Social login block
  &__social-login-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .social-login-container {
      &__social-network {
        display: block;
        width: 264px;
        height: 40px;
        margin: 22px;
        overflow: hidden;
        border: none;
        border-radius: 5px;
        padding: 0px;
        position: relative;
        &-button {
          width: 100%;
          height: 100%;
          display: block;
          font: 16px Helvetica, Arial, sans-serif;
          letter-spacing: 0.25px;
          border: none;
          line-height: 40px;
          color: #{$color-white};
          &-label {
            @include swap_direction(padding, 0 0 0 40px);
            text-align: center;
            display: inline-block;
            width: 100%;
          }
          &_facebook {
            background: #{$color-facebook-theme} url('/media/images/social_login/fb_icon.svg') 0px center no-repeat;
            background-size: 40px 40px;
          }
          &_vkontakte {
            background: #{$color-vkontakte-theme} url('/media/images/social_login/vk_icon.svg') 10px center no-repeat;
            background-size: 30px 30px;
          }
        }
        &-blocker {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
          &_hidden {
            display: none;
          }
        }
      }
    }
  }
  @media #{$medium-down} {
    &__delimeter-horizontal {
      background-color: unset;
    }
    &__social-login-container {
      .social-login-container {
        &__social-network {
          width: 60px;
          height: 60px;
          &-button {
            &-label {
              display: none;
            }
            &_facebook {
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 60px 60px;
            }
            &_vkontakte {
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 40px 40px;
            }
          }
        }
      }
    }
  }
}

#signin {
  .password-field__info {
    @media #{$medium-up} {
      width: 56%;
    }
  }
}
