.elc-product-quick-view-wrapper {
  .elc-product-loyalty-message {
    & + .elc-product-prices-wrapper .elc-product-prices-wrapper,
    & + .elc-product-prices-clickable-wrapper .elc-product-prices-wrapper {
      .elc-product-tax-included-label-wrapper {
        flex: 1;
        display: block;
      }
      .elc-product-price-row-wrapper {
        width: auto;
        flex: 1;
        text-align: $ldirection;
      }
    }
  }
}
.elc-product-brief {
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    .elc-product-tax-included-label-wrapper {
      display: none;
    }
  }
}
.sd-product-grid {
  .elc-grid-item-product {
    .elc-product-loyalty-message-price {
      font-weight: 700;
    }
    .elc-product-prices-wrapper,
    .elc-product-prices-clickable-wrapper {
      .elc-product-tax-included-label-wrapper {
        display: none;
      }
      .elc-price-formatted-wrapper {
        .elc-product-original-price {
          font-family: $roboto-font-family;
          font-weight: 700;
          font-size: 17px;
          color: $color-black-opacity-88;
        }
      }
    }
  }
}
// Hides 'Tax Included' on Non-Loyalty Products
.elc-product-quick-view-wrapper {
  .elc-right-column-section {
    .elc-product-tax-included-label-wrapper {
      display: none;
    }
    .elc-product-full-cta-wrapper {
      .elc-add-to-bag-button {
        padding: 0 20px;
      }
    }
  }
}
