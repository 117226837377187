#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'ПОМОГ (';
              }
              &:after {
                content: ')';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'НЕ ПОМОГ (';
              }
              &:after {
                content: ')';
              }
            }
          }
          .pr-rd-flag-review-container {
            @media #{$small-only} {
              @media #{$portrait} {
                display: block;
              }
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              font-size: 10px;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-multiselect[data-reactid-powerreviews$='озраст'] {
          &::before {
            content: 'ФИЛЬТРЫ:';
            @media #{$small-only} {
              @media #{$portrait} {
                font-size: 9px;
              }
            }
            #{$renutriv} & {
              color: $color-white;
            }
          }
        }
        .pr-rd-review-header-contents {
          .pr-multiselect-button {
            span {
              @media #{$small-only} {
                @media #{$portrait} {
                  font-size: 10px;
                }
              }
            }
            .pr-caret-icon {
              @media #{$small-only} {
                @media #{$portrait} {
                  bottom: 10px;
                }
              }
            }
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='lauder'] {
          display: none;
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 60px;
            .pr-search-icon {
              &::after {
                content: 'Поиск';
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 70px;
            .pr-rd-search-reviews-clear-button {
              right: 70px;
            }
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              font-size: 10px;
              content: 'Сортировать: ';
            }
            .pr-rd-sort {
              font-size: 9px;
              background-size: 20px 43px;
              position: relative;
              top: 2px;
              background-position: 168px;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' из 5';
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .thank-you-page {
        .pr-text {
          .pr-dark {
            vertical-align: top;
          }
          span {
            display: inline-block;
            width: 45%;
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139362_msq74239'],
      .form-group[data-reactid-powerreviews$='ag140774_msq75322'] {
        display: none;
      }
      input[type='checkbox'] {
        left: 0;
      }
    }
  }
}
