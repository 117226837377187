// Force these to be shown on mobile as they're hidden globally on small
// estee_base/scss/sections/loyalty/_loyalty-tout.scss:18
#loyalty__page__tout {
  .info-tier-status-1,
  .info-tier-status-2,
  .info-tier-status-3 {
    display: block;
  }
}

//
// scss/components/header/_page_utilities.scss
//

//.page-utilities-elc-nodeblock,
.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.header-items .page-utilities {
  .page-utilities__account {
    @media #{$medium-up} {
      position: relative;
      margin-top: -10px;
    }
    .user-loyalty-state {
      @media #{$medium-up} {
        #{$rdirection}: 20px;
      }
      @media #{$medium-only} {
        #{$rdirection}: 20px;
      }
      @media #{$large-only} {
        #{$rdirection}: 20px;
      }
    }
    &-button {
      cursor: pointer;
      padding: 0 20px 0 10px;
    }
    &.page-utilities__loyalty {
      @media #{$small-only} {
        .user-login-state {
          .user-greeting {
            display: block;
          }
        }
      }
    }
  }
}

.page-utilities__account-text {
  .elc-user-state-logged-in & {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }
}

.user-login-state {
  @media #{$medium-up} {
    .page-utilities__loyalty & {
      position: static;
      min-width: 0;
    }
  }
}

// loyalty
.user-loyalty-state {
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    right: 20px;
    min-width: 375px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    #{$alt} & {
      color: $color-white;
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        .js-loyalty-discount-value {
          font-weight: bold;
        }
        #{$alt} & {
          color: $color-white;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    right: 100px;
  }
  @media #{$large-only} {
    right: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-left: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
  .sign-out-link {
    margin-left: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
}

.page-utilities__account {
  .user-login-state {
    .page-utilities__account-text {
      a.user-greeting {
        font-size: 11px;
      }
    }
  }
}

#loyalty__panel__points {
  .loyalty__panel__points__benefit-table {
    width: 100%;
    padding: 25px 15px 15px;
  }
  .loyalty__panel__points__benefit {
    padding: 10px;
  }
  .loyalty__panel__points__link {
    display: none;
  }
  @media #{$medium-up} {
    .loyalty__panel__points__benefit-table {
      display: table;
      font-size: 20px;
    }
    .loyalty__panel__points__benefit-table-body {
      display: table-row-group;
    }
    .loyalty__panel__points__benefit-row {
      display: table-row;
    }
    .loyalty__panel__points__benefit {
      display: table-cell;
    }
    .loyalty__panel__points__link {
      float: #{$ldirection};
      display: block;
    }
  }
}

// loyalty pricing grid on MPP & QS
.product_brief__loyalty-grid {
  text-align: left;
  width: 200px;
  margin: auto;
  .product_brief__loyalty-grid__row {
    &.active {
      font-weight: bold;
    }
    .product_brief__loyalty-grid__price {
      width: 35%;
      display: inline-block;
      vertical-align: top;
    }
    .product_brief__loyalty-grid__description {
      max-width: 60%;
      display: inline-block;
    }
  }
}

.quickshop__loyalty-grid {
  .quickshop__loyalty-grid__row {
    &.active {
      font-weight: bold;
    }
    .quickshop__loyalty-grid__size,
    .quickshop__loyalty-grid__price {
      display: inline-block;
      width: 20%;
    }
  }
}

.quickshop__price-text {
  width: 100%;
}

.quickshop-inline__loyalty-grid {
  .quickshop-inline__loyalty-grid__row {
    &.active {
      font-weight: bold;
    }
    .quickshop-inline__loyalty-grid__price {
      width: 25%;
      display: inline-block;
    }
    .quickshop-inline__loyalty-grid__description {
      display: inline-block;
    }
  }
}
/* Moved the CSS from Node: 48458 */
.node-48458 {
  .loyalty_multi_image_tout {
    min-height: 560px;
  }
  .loyalty_points {
    li {
      margin-bottom: 1em;
    }
    text-align: #{$ldirection};
    font-size: 17px;
    margin: 20px auto 50px;
    width: 805px;
    padding: 35px 20px 40px 45px;
    h3 {
      font-size: 34px;
      padding-#{$rdirection}: 25px;
    }
  }
  .clearfix {
    &.ee-landing-tiles {
      h3 {
        margin: 30px 0 15px;
        font-size: 38px;
        text-align: center;
      }
      @media #{$medium-only} {
        margin: 25px 0;
      }
    }
  }
  .loyalty_mrkt_ldng {
    .loyalty_multi_image_tout {
      .sub_title {
        position: relative;
        height: 120px;
      }
      .loyalty_multi_image_tout_left {
        padding: 0;
      }
    }
    .loyalty_join_signin {
      font-weight: bold;
      text-transform: uppercase;
      font-family: $akzidenz;
      font-size: 14px;
      width: 900px;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      @media #{$large-only} {
        margin-bottom: 20px;
      }
      @media #{$medium-only} {
        margin-top: 180px;
      }
      p {
        text-align: center;
      }
      .not_loyalty_join_now {
        display: block;
      }
      &.second {
        margin: 0 auto 100px;
      }
    }
    &::before {
      background: url('/media/images/loyalty/loyalty_marketing_bg_v2.jpg');
      background-repeat: no-repeat;
    }
    .loyalty_multi_image_tout_right {
      margin-top: 95px;
    }
    .js-sign-in-popup {
      display: block;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
    .loyalty_tiers {
      height: 450px;
      p {
        padding-#{$rdirection}: 30px;
        font-family: $optimatext;
        font-size: 28px;
        margin-bottom: 25px;
        font-weight: 600;
        margin-top: 0;
      }
      .tier {
        span {
          padding-#{$rdirection}: 30px;
          font-size: 20px;
          font-family: $akzidenz;
        }
        h4 {
          padding-#{$rdirection}: 27px;
        }
      }
      .tier_left,
      .tier_right {
        p {
          font-family: $akzidenz;
          font-weight: 400;
        }
      }
    }
  }
  .title1 {
    @media #{$large-only} {
      position: absolute;
      letter-spacing: 1px;
      font-weight: bold;
      #{$rdirection}: 30%;
      top: 25px;
      font-size: 28px;
    }
    @media #{$medium-only} {
      font-size: 22px;
    }
  }
  .title2 {
    @media #{$large-only} {
      position: absolute;
      #{$rdirection}: 0;
      bottom: 0;
      font-size: 100px;
    }
    @media #{$medium-only} {
      font-size: 18vw;
    }
  }
  .loyalty_header_table {
    td {
      text-align: #{$ldirection};
      @media #{$medium-only} {
        font-size: 10px;
        line-height: 1.5;
        width: 33%;
      }
    }
  }
  .loyalty_list {
    padding: 10px 35px 0;
    li {
      padding: 4px;
      list-style-type: disc;
      list-style-position: outside;
    }
  }
  .loyalty_table {
    text-align: center;
    overflow: scroll;
    margin: 10px 15px;
    border-collapse: collapse;
    td {
      border: $checkout-line-lighter-gray;
      padding: 15px;
      min-width: 165px;
      width: 33%;
    }
  }
  .button_loyalty_terms {
    color: $color-navy;
    background: none;
    text-decoration: underline;
  }
  .form-submit {
    width: 280px;
    height: 50px;
    display: none;
  }
  .loyalty_question {
    font-size: 12px;
    text-transform: uppercase;
    a {
      color: $color-white;
      padding-#{$rdirection}: 25px;
    }
  }
  @media #{$small-only} {
    .loyalty_market {
      text-align: center;
      &__points__container {
        background: url('/media/export/cms_2.0/loyalty/mobile/loyalty_marketing_bg_2_slider_ru.jpg') no-repeat 0 0;
        margin-top: 22px;
      }
      &__points {
        li {
          margin-bottom: 1em;
          list-style: disc;
        }
        ul {
          list-style-position: outside;
          text-align: #{$ldirection};
          padding: 20px 0;
        }
        background: $color-navy;
        color: $color-white;
        padding: 18px 10px 28px;
        text-align: center;
        display: block;
        a {
          color: $color-white;
        }
      }
      &__cta {
        display: none;
      }
      &__description {
        background: url('/media/export/cms_2.0/loyalty/mobile/loyalty_marketing_bg01.jpg') no-repeat 100% 38%;
        margin: 0 5px;
        h3 {
          margin: 30px 0 15px;
          font-size: 38px;
          text-align: center;
          line-height: 0.85;
        }
      }
      &__tier__percents {
        font-family: $optimatext;
        font-size: 28px;
        font-weight: bold;
        text-align: right;
      }
      &__tiers {
        display: none;
      }
      &__btn {
        &__join,
        &__signin {
          display: none;
        }
      }
    }
    .field-mobile-template {
      .loyalty_multi_image_tout {
        background: url('/media/export/cms_2.0/loyalty/mobile/loyalty_marketing_bg_slider_ru.png') no-repeat scroll 0 100% auto;
      }
    }
    h1 {
      &.sub_title {
        position: relative;
        height: 90px;
      }
    }
    .loyalty-main-text {
      margin: 0 10px;
    }
    .loyalty_body_table {
      text-align: center;
      margin: 5px;
      border-collapse: collapse;
      td {
        border: $checkout-line-lighter-gray;
        text-align: #{$ldirection};
        font-size: 9px;
        line-height: 1.5;
        width: 33%;
      }
    }
    .button_loyalty {
      font-size: 12px;
      font-family: $akzidenz;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      font-weight: bold;
      background: $color-navy;
      color: $color-white;
      text-align: center;
      text-decoration: none;
      display: block;
      width: 271px;
      margin: 0 auto 20px;
    }
  }
}

.page-node-48458 {
  #cboxContent {
    background: url('/media/export/cms_2.0/loyalty/signin/bg01.jpg') no-repeat 0 0, url('/media/export/cms_2.0/loyalty/signin/bg02.jpg') no-repeat 20% 100%, url('/media/export/cms_2.0/loyalty/signin/bg03.jpg') no-repeat 100% 100%;
    background-color: $color-white;
    background-image: none;
  }
  .sign-in-component {
    &.registration {
      padding-top: 10px;
    }
  }
}
