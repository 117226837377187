@import 'global/_variables';
/**
* Note: there's a bug in iOS where the Ruble doesn't display in various fonts.
* If there's any chance of one showing up, we should use $price-font (Helvetica Neue) for the font-family.
*/
body {
  font-family: $price-font !important;
}

.select-markdown,
a.selectBox,
a.selectbox,
select {
  font-family: $price-font !important;
}

.spp-product__section {
  font-family: $price-font !important;
}

.discover_more__product-price,
.product-brief__price {
  font-family: $price-font !important;
  .text--bold {
    font-family: $price-font !important;
  }
}

.cart-item__color {
  font-family: $price-font !important;
}

.brand-aerin,
.page-products-aerin {
  .page-main {
    font-family: $price-font !important;
    p,
    h1,
    h2,
    h4,
    h5,
    h6 {
      font-family: $price-font !important;
    }
    a.selectBox,
    a.selectbox,
    .button,
    blockquote,
    .pull-quote,
    select {
      font-family: $price-font !important;
    }
    h3 {
      font-family: $price-font !important;
    }
  }
}

.device-pc .brand-aerin {
  .page-main {
    .discover_more__product-header,
    .discover_more__product-sub-header {
      font-family: $price-font !important;
    }
  }
}

.brand-aerin {
  .page-main {
    .text--short,
    .checkout-page-title,
    .text-size,
    .review-submit__header,
    label,
    .cart-item__qty,
    .headline--medium,
    .headline--page,
    .headline--large,
    .headline--primary,
    .text-product-names-sub,
    .product-brief__view-details,
    .product-full__misc-flag,
    .product_brief__price,
    .product_brief__misc-flag {
      font-family: $price-font !important;
    }
    .headline--secondary,
    .add-samples-message,
    .headline--tertiary,
    .headline--quaternary,
    .headline--section,
    .headline--quinary,
    .text--tiny,
    .text--tiny-copy,
    .headline--senary,
    .pc-place-order,
    .text--long-copy,
    .text-batch-2-primary-headline,
    .text-long-body-copy,
    .text-promo,
    .headline--products,
    .mpp__header,
    .text-primary-headlines,
    .text-medium-headlines,
    .text-product-names-main {
      font-family: $price-font !important;
    }
    .text-product-listing-names-sub,
    .product_brief__sub-header,
    .text-benefits-line-sub,
    .spp-product__review,
    .product-full__review,
    .text-batch-2-medium-headline,
    .page-footer,
    .text-form-text,
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='password'],
    textarea,
    .form-text,
    select {
      font-family: $price-font !important;
    }
    .device-pc {
      .discover_more__product-header,
      .discover_more__product-sub-header {
        font-family: $price-font !important;
      }
    }
    .spp-product__attribute,
    .product-full__attribute {
      p {
        font-family: $price-font !important;
      }
    }
    .spp-product__details.spp-product__details--estee-edit {
      .spp-product__details-column02 {
        .spp-product__details-attribute__label {
          font-family: $price-font !important;
        }
      }
    }
    .ff-results__product {
      .sku-brief-editorial__shadename {
        font-family: $price-font !important;
      }
    }
    .beauty-feed-tout--products {
      .sku-brief-editorial__shadename {
        font-family: $price-font !important;
      }
    }
    .section-store-locator.device-pc {
      .store-locator {
        .store-locator__title {
          font-family: $price-font !important;
        }
      }
    }
    .typeahead-wrapper {
      .product-result__name,
      .product-result__sub-name {
        font-family: $price-font !important;
      }
    }
    .typeahead-summary {
      p {
        font-family: $price-font !important;
      }
    }
    .checkout__sidebar {
      .links-panel__title {
        font-family: $price-font !important;
      }
    }
    .samples-page {
      .available {
        font-family: $price-font !important;
      }
    }
    .loyalty_mrkt_ldng {
      .loyalty_multi_image_tout {
        font-family: $price-font !important;
      }
    }
    .page-sticky-footer {
      .page-sticky-footer__left {
        .node-elc-nodeblock {
          .sticky-footer__my-feed-link {
            .item-count {
              font-family: $price-font !important;
            }
          }
        }
      }
      .page-sticky-footer__right {
        .menu > li {
          .sticky-footer__my-feed-link {
            .item-count {
              font-family: $price-font !important;
            }
          }
        }
      }
    }
    h3.product_brief__header {
      font-family: $price-font !important;
    }
  }
  .aerin-video-block__pretitle,
  .aerin-image-block-w-overlay__text--subtitle,
  .aerin-video-block__play-text,
  .aerin_headline_block--subtitle,
  .aerin-slot-button {
    font-family: $price-font !important;
  }
  .aerin-image-block-w-overlay__overlay.enable-overlay {
    .aerin-image-block-w-overlay__text {
      font-family: $price-font !important;
    }
  }
  .aerin-footer-tout__headline {
    font-family: $price-font !important;
  }
  .discover-more {
    .discover-more__products {
      .discover_more__product-url {
        .discover_more__product-header {
          font-family: $price-font !important;
        }
      }
    }
  }
}

.page-product-aerin {
  h3 {
    font-family: $price-font !important;
  }
  .promotions {
    .promo-unit__headline {
      font-family: $price-font !important;
    }
  }
  .spp-product__details {
    .spp-product__details-header {
      font-family: $price-font !important;
    }
  }
}

.brand-aerin {
  .page-main {
    .text-product-anchor,
    .spp-product__anchor,
    .spp-product,
    .product-full,
    .ee-landing__filters,
    .product-full__detail-link,
    .product-full__share,
    .product-full__wishlist,
    .ee--subhead {
      font-family: $price-font !important;
    }
    #colorbox.colorbox__quickshop,
    #product_brief__quickshop__container {
      .quickshop,
      .quickshop__review {
        font-family: $price-font !important;
      }
      .quickshop__attribute {
        p {
          font-family: $price-font !important;
        }
      }
    }
    .sms-signup__terms {
      label {
        font-family: $price-font !important;
      }
    }
    .special-offers-tray {
      .offers-email-signup-form {
        .headline--offers-tray,
        .offers-register-form,
        .offers-sign-in-form {
          .headline--offers-tray {
            font-family: $price-font !important;
          }
        }
        .offers-email-signup-form__sign-in-wrapper,
        .offers-email-signup-form__message {
          font-family: $price-font !important;
        }
      }
      .gift-wrap,
      .special-offer-benefits {
        .gift-wrap__content,
        .special-offer-benefits__content {
          font-family: $price-font !important;
        }
      }
    }
    .selectBox-options {
      li {
        a {
          font-family: $price-font !important;
        }
      }
    }
    .chat-parallax__headline {
      font-family: $price-font !important;
    }
    .homepage-subscribe {
      .homepage-subscribe__header {
        font-family: $price-font !important;
      }
    }
    .woa-blog__content {
      .ee-disco-more__label {
        font-family: $price-font !important;
      }
    }
    .BVRRRootElement {
      font-family: $price-font !important;
      input,
      select,
      textarea {
        font-family: $price-font !important;
      }
    }
    .BVRRPhotoPopup,
    .BVRRVideoPopup,
    .text-product-anchor,
    .spp-product__anchor,
    .spp-product,
    .product-full {
      font-family: $price-font !important;
    }
    .BVRRUserNickname {
      font-family: $price-font !important;
      a {
        font-family: $price-font !important;
      }
    }
    .spp-product__details {
      .spp-product__details-header,
      .spp-product__details-description {
        font-family: $price-font !important;
      }
    }
    .BVDIBody,
    .BVVIVideoPopup,
    .BVRRBuyAgainContainer,
    .BVRRReviewTitle,
    .BVRRQuickTakeLabel,
    .BVRRTitle {
      font-family: $price-font !important;
    }
    .BVDI_QT {
      .BVRRRatingOverall {
        .BVRRRatingNormalOutOf,
        .BVRRRatingRangeNumber {
          font-family: $price-font !important;
        }
      }
    }
    .BVRRQuickTakeSummary {
      .BVRRRatingSummaryLinks {
        .BVRRRatingSummaryLinkWrite a:link,
        a:visited {
          font-family: $price-font !important;
        }
      }
    }
    .BVDI_QTHeaderAttributes {
      .BVDITitle {
        font-family: $price-font !important;
      }
    }
    .BVDI_FV {
      .BVDI_FVVote {
        a {
          font-family: $price-font !important;
        }
      }
    }
    .BVDI_FVReportLink {
      a:visited,
      a,
      a:hover,
      a:focus,
      a:active {
        font-family: $price-font !important;
      }
    }
    .hero-block--aerin {
      .hero-block__promo {
        .text-promo__content {
          font-family: $price-font !important;
        }
      }
      .cta__button {
        font-family: $price-font !important;
      }
    }
    .hero-block--aerin.button-dark {
      font-family: $price-font !important;
    }
    .sign-in-component {
      .sign-in-component__confirm--registration {
        .sign-in-component__header {
          font-family: $price-font !important;
        }
      }
    }
  }
}

.mpp {
  font-family: $price-font !important;
  .headline--products,
  .mpp__header {
    font-family: $price-font !important;
  }
  h2,
  h5,
  h3,
  h4 {
    font-family: $price-font !important;
  }
}

.spp {
  font-family: $price-font !important;
  .spp-product,
  .h3,
  h4,
  h5 {
    font-family: $price-font !important;
  }
  .text-product-names-sub {
    font-family: $price-font !important;
  }
  .BVDI_QT {
    .BVRRRatingOverall {
      .BVRRRatingNormalOutOf,
      .BVRRRatingRangeNumber {
        font-family: $price-font !important;
      }
    }
  }
  .BVRRTitle {
    font-family: $price-font !important;
  }
}

.discover-more {
  .discover-more__products {
    .discover_more__product-url {
      .discover_more__product-sub-header {
        font-family: $price-font !important;
      }
      .discover_more__product-header {
        font-family: $price-font !important;
      }
    }
  }
}

.spp,
.page-product {
  .product-full__title {
    font-family: $price-font !important;
  }
}

.product-thumb {
  .product-thumb__price {
    font-family: $price-font !important;
  }
}

.product-full__offer {
  h5 {
    font-family: $price-font !important;
  }
}

.product-full__attribute {
  h5 {
    font-family: $price-font !important;
  }
}

.spp-product__attribute {
  h5 {
    font-family: $price-font !important;
  }
}

.spp-product__mini-bag-header,
.spp-product__mini-bag-sub-header,
.product_brief__sub-header,
.text-product-listing-names-sub,
.spp-product__mini-bag-section,
.text-product-listing-names-main {
  font-family: $price-font !important;
}

page-products,
.mpp {
  .product_brief__header,
  h3 {
    font-family: $price-font !important;
  }
}

.ff-results__product {
  .sku-brief-editorial__shadename {
    font-family: $price-font !important;
  }
}

.ff-results__regimen-step-product {
  .product_brief__sub-header,
  .product_brief__header {
    font-family: $price-font !important;
  }
}

.beauty-feed-tout--products {
  .sku-brief-editorial__shadename {
    font-family: $price-font !important;
  }
}

.typeahead-wrapper {
  .product-result__sub-name,
  .product-result__name {
    font-family: $price-font !important;
  }
}
//footer & Gnav
.page-footer {
  .email_signup__header,
  .footer-header,
  .country_chooser__header,
  .sms-signup__header {
    font-family: $price-font !important;
  }
}

.page-sticky-footer {
  .drawer-formatter__content {
    .special-offer {
      .special-offer__text,
      .special-offer__header {
        font-family: $price-font !important;
      }
      .cta__button {
        font-family: $price-font !important;
      }
    }
  }
}

.special-offers-tray {
  .special-offer-benefits {
    .special-offer-benefits__content,
    .gift-wrap__content {
      font-family: $price-font !important;
    }
  }
  .gift-wrap {
    .gift-wrap__content,
    .special-offer-benefits__content {
      font-family: $price-font !important;
    }
  }
  .offers-email-signup-form {
    .headline--offers-tray {
      font-family: $price-font !important;
    }
  }
}

.page-navigation {
  .depth-1 {
    h3 {
      font-family: $price-font !important;
    }
  }
}

.page-navigation__hotswap .depth-1.menu-container--has-nav-tout .level-2 h3 {
  font-family: $price-font !important;
}

.checkout__panel-title,
.search-product__header,
h5 {
  font-family: $price-font !important;
}

#rn_container {
  h3 {
    font-family: $price-font !important;
  }
  p {
    font-family: $price-font !important;
  }
  #main_container {
    #main_content {
      h3 {
        font-family: $price-font !important;
      }
      p {
        font-family: $price-font !important;
      }
    }
  }
}

#rn_container {
  #renutriv_section-1,
  #renutriv_section-2 {
    .content {
      p {
        font-family: $price-font !important;
      }
      div.content {
        a.video {
          font-family: $price-font !important;
        }
      }
    }
    div.content {
      a.video {
        font-family: $price-font !important;
      }
    }
  }
  #renutriv_section-3,
  #renutriv_section-4 {
    .content {
      .top {
        p {
          font-family: $price-font !important;
        }
      }
      li {
        p {
          font-family: $price-font !important;
        }
      }
    }
  }
  #renutriv_section-6 {
    .content {
      ul {
        li {
          a {
            font-family: $price-font !important;
          }
        }
      }
    }
  }
  div.side_nav {
    li {
      a {
        font-family: $price-font !important;
      }
    }
  }
}

div#section_1.renutriv_content {
  div.div_content_wrapper {
    div.div_content {
      h3 {
        font-family: $price-font !important;
      }
      &_body {
        font-family: $price-font !important;
      }
    }
  }
}

#renutriv_formatter {
  div.mpp.js-mpp-wrapper {
    h2.mpp__header {
      font-family: $price-font !important;
    }
    h3.product_brief__header {
      font-family: $price-font !important;
    }
  }
}

.brand-renutriv {
  .page-main {
    font-family: $price-font !important;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $price-font !important;
    }
  }
  .product_brief__buttons--non-shaded {
    .product_brief__button--shop-now {
      font-family: $price-font !important;
    }
  }
  .product_brief__price {
    font-family: $price-font !important;
  }
  .hero-tout-fullwidth {
    .text-promo {
      font-family: $price-font !important;
    }
    .cta-button-wrapper {
      .cta {
        font-family: $price-font !important;
      }
    }
  }
  select,
  a.selectBox,
  a.selectbox {
    font-family: $price-font !important;
  }
}

div#shop_by.renutriv_content {
  div {
    ul {
      li {
        font-family: $price-font !important;
      }
    }
  }
}

.video_youtube.fonts-theme-re-nutriv {
  .headline--primary {
    font-family: $price-font !important;
    span {
      font-family: $price-font !important;
    }
  }
  .video_youtube__content {
    .text-promo {
      font-family: $price-font !important;
    }
  }
}

.page-products-re-nutriv {
  font-family: $price-font !important;
  .headline--products,
  .mpp__header {
    font-family: $price-font !important;
  }
  select,
  a.selectBox,
  a.selectbox,
  .back-to-top,
  .button--light,
  .btn,
  .button--dark,
  .cta,
  .product_brief__price,
  .product_brief__label2,
  .headline--subsection,
  .text--bold,
  .text-small-header,
  .product_brief__misc-flag {
    font-family: $price-font !important;
  }
  .text-product-listing-names-main,
  .product_brief__header {
    font-family: $price-font !important;
  }
  .ff-results__regimen-step-product {
    .product_brief__header {
      font-family: $price-font !important;
    }
  }
  .typeahead-wrapper {
    .product-result__name {
      font-family: $price-font !important;
    }
  }
  .text-product-listing-names-sub,
  .product_brief__sub-header {
    font-family: $price-font !important;
  }
  .ff-results__product {
    .sku-brief-editorial__shadename {
      font-family: $price-font !important;
    }
  }
  .ff-results__regimen-step-product {
    .product_brief__sub-header {
      font-family: $price-font !important;
    }
  }
  .beauty-feed-tout--products {
    .sku-brief-editorial__shadename {
      font-family: $price-font !important;
    }
  }
  .typeahead-wrapper {
    .product-result__sub-name {
      font-family: $price-font !important;
    }
  }
}

.page-product-re-nutriv {
  font-family: $price-font !important;
  h3,
  h4,
  .promo-unit__headline,
  .text-benefits-line-main,
  .text-product-names-sub,
  .text-benefits-line-main,
  .headline--secondary,
  .headline--medium,
  .discover-more__header,
  .headline--quinary,
  .discover_more__product-header,
  .spp-product__mini-bag-header,
  .spp-product__mini-bag-sub-header,
  .headline--senary,
  .discover_more__product-sub-header,
  .pc-place-order {
    font-family: $price-font !important;
  }
  .spp-product__attribute,
  .product-full__attribute {
    h5 {
      font-family: $price-font !important;
    }
  }
  .spp-product__details {
    .spp-product__details-attribute,
    .spp-product__details-description {
      font-family: $price-font !important;
    }
  }
  .product-thumb {
    .product-thumb__price {
      font-family: $price-font !important;
    }
  }
  .product-thumb {
    .product-thumb__price {
      font-family: $price-font !important;
    }
  }
  .text-benefits-line-sub,
  .spp-product__review,
  .product-full__review,
  .product-full__detail-link,
  .product-full__share,
  .product-full__wishlist,
  .wishlist-confirm__button-close,
  .spp-product__detail-link,
  .spp-product__share,
  .spp-product__wishlist,
  .text-links,
  .text-product-anchor,
  .spp-product__anchor,
  .spp-product,
  .product-full {
    font-family: $price-font !important;
  }
  .free_standard_shipping_returns,
  select,
  a.selectBox,
  a.selectbox,
  .button--dark,
  .cta,
  p,
  .button--light,
  .btn,
  .spp-product__mini-bag-section,
  .BVRRPhotoPopup,
  .BVRRVideoPopup {
    font-family: $price-font !important;
  }
  .spp-product__attribute,
  .product-full__attribute {
    p {
      font-family: $price-font !important;
    }
  }
  .beauty-chat-tray {
    .beauty-chat-tray__learn-more {
      font-family: $price-font !important;
    }
  }
  .spp-product__details.spp-product__details--estee-edit {
    .spp-product__details-column02 {
      .spp-product__details-attribute__label {
        font-family: $price-font !important;
      }
    }
  }
  .spp-product__details {
    .spp-product__details-header {
      font-family: $price-font !important;
    }
  }
  .spp-product__details {
    .spp-product__details-attribute__label {
      font-family: $price-font !important;
    }
  }
  .discover-more {
    .discover-more__products {
      .discover_more__product-url {
        .discover_more__product-header,
        .discover_more__product-sub-header {
          font-family: $price-font !important;
        }
      }
    }
  }
  .BVRRRootElement {
    font-family: $price-font !important;
    input,
    select,
    textarea {
      font-family: $price-font !important;
    }
  }
  .BVDIBody,
  .BVVIVideoPopup,
  .BVRRPhotoPopup,
  .BVRRVideoPopup {
    font-family: $price-font !important;
  }
  .BVDI_QT {
    .BVRRRatingOverall {
      .BVRRRatingNormalOutOf,
      .BVRRRatingRangeNumber {
        font-family: $price-font !important;
      }
    }
  }
  .BVRRQuickTakeSummary {
    .BVRRRatingSummaryLinks {
      .BVRRRatingSummaryLinkWrite {
        a:link,
        a:visited {
          font-family: $price-font !important;
        }
      }
    }
  }
}

.brand-renutriv {
  #colorbox.colorbox__quickshop,
  #product_brief__quickshop__container {
    .quickshop {
      font-family: $price-font !important;
    }
    .quickshop__tabs-control {
      .quickshop__tab-control {
        font-family: $price-font !important;
      }
    }
    .quickshop__detail-link,
    .quickshop__share,
    .quickshop__wishlist {
      font-family: $price-font !important;
    }
  }
}

//section-3-minute-beauty

.section-3-minute-beauty {
  #tmb_container {
    .mb_boutique_quote {
      h3 {
        font-family: $price-font !important;
      }
    }
    .mb_boutique_content {
      h2 {
        font-family: $price-font !important;
      }
    }
  }
  #tips_boutique {
    h1,
    h2,
    h3,
    h4 {
      font-family: $price-font !important;
    }
  }
}

.corporate-information-page {
  h5 {
    font-family: $price-font !important;
  }
}

.cart-item__product-subname,
.cart-item__size,
.search-product__sub-header,
.cart-item__total,
.subtotal.value {
  font-family: $price-font !important;
}

.ff-quiz__inner {
  .ff-quiz__button--next {
    font-family: $price-font !important;
  }
}
//section-holiday
.section-holiday {
  #holiday-2016_tile-1 {
    .holiday-decoration.text {
      p {
        font-style: italic;
      }
    }
  }
  #holiday-2016_tile-6,
  #holiday-2016_tile-9 {
    .holiday-decoration {
      p.decorative-text {
        font-family: $price-font;
      }
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .map-container {
      .address-map-directions-panel,
      .address-map-location-panel {
        header {
          h2 {
            font-family: $price-font !important;
          }
        }
      }
    }
  }
}

.cart-item__product-name {
  a {
    font-family: $price-font !important;
  }
}

.text-product-listing-names-main {
  font-family: $price-font !important;
}

.night-experience-page {
  .anr-night-experience__close-x {
    font-family: $price-font;
  }
  .anr-night-experience__carousel-text {
    font-family: $price-font;
  }
  .anr-night-experience__module2 {
    .anr-night-experience__carousel-text {
      font-family: $price-font;
    }
  }
  #anr-night-experience__basic-image-text35 {
    font-family: $price-font;
  }
  .anr-night-experience__basic-image_2-text_content {
    font-family: $price-font;
  }
  .anr-night-experience__headings {
    font-family: $price-font;
  }
  .anr-night-experience_mini_product_info {
    font-family: $price-font;
  }
  .anr-night-experience__edit_centre_title {
    font-family: $price-font;
  }
  .anr-night-experience__text_best_night_content {
    font-family: $price-font;
  }
  .anr-night-experience__text_plus_content {
    font-family: $price-font;
  }
  .anr-simple__sec2plus-headline {
    font-family: $price-font;
  }
  .anr-night-experience__carousel-4-text5 {
    font-family: $price-font;
  }
  .anr-night-experience__carousel-4-text6 {
    font-family: $price-font;
  }
  .anr-night-experience__carousel-4-text7 {
    font-family: $price-font;
  }
  .anr-night-experience__carousel-4-text8 {
    font-family: $price-font;
  }
  .anr-night-experience__quick-shop {
    font-family: $price-font;
  }
  .anr-night-experience__anr_quick_shop_container {
    font-family: $price-font;
  }
  #anr-night-experience__basic-image-text12,
  #anr-night-experience__basic-image-text13 {
    font-family: $price-font;
  }
  .anr-night-experience__basic-image_2-text_bullets {
    font-family: $price-font;
  }
  .anr-night-experience__reviewBtn {
    font-family: $price-font;
  }
  .anr-night-experience__desc {
    font-family: $price-font;
  }
  .anr-night-experience__edit_centre_sub_title {
    font-family: $price-font;
  }
  .anr-night-experience__edit_read_article {
    font-family: $price-font;
  }
}
