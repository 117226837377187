//Product Foundation VTO
.product-foundation-vto {
  &__mobile-ctrl {
    height: 227px;
    ul.shade-list-vto {
      height: 95px;
      li {
        .vto-filter-text {
          @include transform(translateX(-50%));
          font-size: 12px;
          position: absolute;
          #{$ldirection}: 50%;
          white-space: initial;
        }
        &.active,
        &.vto-filter {
          vertical-align: top;
        }
      }
    }
    .container {
      .product-sub-heading {
        font-size: 12px;
      }
      .product-full {
        &__add-button {
          @include swap_direction(padding, 0 10px);
          font-size: 11px;
          line-height: normal;
        }
      }
    }
  }
  &__btn-container {
    .see-my-perfect,
    .see-all-shades {
      font-size: 12px;
    }
  }
}
